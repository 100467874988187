import React from "react";
// Logos
import { ReactComponent as Agromex } from "../../../assets/svg/logos/Agromex.svg";
import { ReactComponent as APJoergensen } from "../../../assets/svg/logos/AP_Joergensen.svg";
import { ReactComponent as BalticAgro } from "../../../assets/svg/logos/Baltic_Agro.svg";
import { ReactComponent as Ceravis } from "../../../assets/svg/logos/Ceravis.svg";
import { ReactComponent as DanishAgro } from "../../../assets/svg/logos/Danish_Agro.svg";
import { ReactComponent as DAVAFoods } from "../../../assets/svg/logos/DAVA_Foods.svg";
import { ReactComponent as DLAAgro } from "../../../assets/svg/logos/DLA_Agro.svg";
import { ReactComponent as Hankkija } from "../../../assets/svg/logos/Hankkija.svg";
import { ReactComponent as Hatting } from "../../../assets/svg/logos/Hatting.svg";
import { ReactComponent as HerculesWildbird } from "../../../assets/svg/logos/Hercules_Wildbird.svg";
import { ReactComponent as HungariaAgro } from "../../../assets/svg/logos/Hungaria_Agro.svg";
import { ReactComponent as NordicSeed } from "../../../assets/svg/logos/Nordic_Seed.svg";
import { ReactComponent as NorwegianAgro } from "../../../assets/svg/logos/Norwegian_Agro.svg";
import { ReactComponent as PolishAgro } from "../../../assets/svg/logos/Polish_Agro.svg";
import { ReactComponent as Raitech } from "../../../assets/svg/logos/Raitech.svg";
import { ReactComponent as RingeMaskinforretning } from "../../../assets/svg/logos/Ringe_Maskinforretning.svg";
import { ReactComponent as RoestofteMaskiner } from "../../../assets/svg/logos/Roestofte_Maskiner.svg";
import { ReactComponent as Scanfedt } from "../../../assets/svg/logos/Scanfedt.svg";
import { ReactComponent as Scanola } from "../../../assets/svg/logos/Scanola.svg";
import { ReactComponent as SwedishAgro } from "../../../assets/svg/logos/Swedish_Agro.svg";
import { ReactComponent as TraktorHoest } from "../../../assets/svg/logos/Traktor_Hoest.svg";
import { ReactComponent as Traktorservice } from "../../../assets/svg/logos/Traktorservice.svg";
import { ReactComponent as Vilofarm } from "../../../assets/svg/logos/Vilofarm.svg";
import { ReactComponent as Vilomix } from "../../../assets/svg/logos/Vilomix.svg";
import { ReactComponent as Vilovet } from "../../../assets/svg/logos/Vilovet.svg";
import { ReactComponent as VinderupMaskiner } from "../../../assets/svg/logos/Vinderup_Maskiner.svg";
import { ReactComponent as CeraGreen } from "../../../assets/svg/logos/CeraGreen.svg";
import { ReactComponent as Agrosan } from "../../../assets/svg/logos/Agrosan.svg";
import { ReactComponent as Trinol } from "../../../assets/svg/logos/Trinol.svg";
import { ReactComponent as LollandFalsterBioenergi } from "../../../assets/svg/logos/Lolland_Falster_Bioenergi.svg";

export enum DA_LogoNames {
    Agromex = "Agromex",
    APJoergensen = "AP Joergensen",
    BalticAgro = "Baltic Agro",
    Ceravis = "Ceravis",
    DanishAgro = "Danish Agro",
    DAVAFoods = "DAVA Foods",
    DLAAgro = "DLA Agro",
    Hankkija = "Hankkija",
    Hatting = "Hatting",
    HerculesWildbird = "Hercules Wildbird",
    HungariaAgro = "Hungaria Agro",
    NordicSeed = "Nordic Seed",
    NorwegianAgro = "Norwegian Agro",
    PolishAgro = "Polish Agro",
    Raitech = "Raitech",
    RingeMaskinforretning = "Ringe Maskinforretning",
    RoestofteMaskiner = "Roestofte Maskiner",
    Scanfedt = "Scanfedt",
    Scanola = "Scanola",
    SwedishAgro = "Swedish Agro",
    TraktorHoest = "Traktor Hoest",
    Traktorservice = "Traktorservice",
    Vilofarm = "Vilofarm",
    Vilomix = "Vilomix",
    Vilovet = "Vilovet",
    VinderupMaskiner = "VinderupMaskiner",
    CeraGreen = "CeraGreen",
    Agrosan = "Agrosan",
    Trinol = "Trinol",
    LollandFalsterBioenergi = "Lolland Falster Bioenergi",
}

export interface DA_LogoProps {
    name: DA_LogoNames;
}

export const DA_Logo = ({ name }: DA_LogoProps) => {
    let ReturnLogo = React.Fragment;

    switch (name) {
        case DA_LogoNames.Agromex:
            ReturnLogo = Agromex;
            break;
        case DA_LogoNames.APJoergensen:
            ReturnLogo = APJoergensen;
            break;
        case DA_LogoNames.BalticAgro:
            ReturnLogo = BalticAgro;
            break;
        case DA_LogoNames.Ceravis:
            ReturnLogo = Ceravis;
            break;
        case DA_LogoNames.DanishAgro:
            ReturnLogo = DanishAgro;
            break;
        case DA_LogoNames.DAVAFoods:
            ReturnLogo = DAVAFoods;
            break;
        case DA_LogoNames.DLAAgro:
            ReturnLogo = DLAAgro;
            break;
        case DA_LogoNames.Hankkija:
            ReturnLogo = Hankkija;
            break;
        case DA_LogoNames.Hatting:
            ReturnLogo = Hatting;
            break;
        case DA_LogoNames.HerculesWildbird:
            ReturnLogo = HerculesWildbird;
            break;
        case DA_LogoNames.HungariaAgro:
            ReturnLogo = HungariaAgro;
            break;
        case DA_LogoNames.NordicSeed:
            ReturnLogo = NordicSeed;
            break;
        case DA_LogoNames.NorwegianAgro:
            ReturnLogo = NorwegianAgro;
            break;
        case DA_LogoNames.PolishAgro:
            ReturnLogo = PolishAgro;
            break;
        case DA_LogoNames.Raitech:
            ReturnLogo = Raitech;
            break;
        case DA_LogoNames.RingeMaskinforretning:
            ReturnLogo = RingeMaskinforretning;
            break;
        case DA_LogoNames.RoestofteMaskiner:
            ReturnLogo = RoestofteMaskiner;
            break;
        case DA_LogoNames.Scanfedt:
            ReturnLogo = Scanfedt;
            break;
        case DA_LogoNames.Scanola:
            ReturnLogo = Scanola;
            break;
        case DA_LogoNames.SwedishAgro:
            ReturnLogo = SwedishAgro;
            break;
        case DA_LogoNames.TraktorHoest:
            ReturnLogo = TraktorHoest;
            break;
        case DA_LogoNames.Traktorservice:
            ReturnLogo = Traktorservice;
            break;
        case DA_LogoNames.Vilofarm:
            ReturnLogo = Vilofarm;
            break;
        case DA_LogoNames.Vilomix:
            ReturnLogo = Vilomix;
            break;
        case DA_LogoNames.Vilovet:
            ReturnLogo = Vilovet;
            break;
        case DA_LogoNames.VinderupMaskiner:
            ReturnLogo = VinderupMaskiner;
            break;
        case DA_LogoNames.CeraGreen:
            ReturnLogo = CeraGreen;
            break;
        case DA_LogoNames.Agrosan:
            ReturnLogo = Agrosan;
            break;
        case DA_LogoNames.Trinol:
            ReturnLogo = Trinol;
            break;
            case DA_LogoNames.LollandFalsterBioenergi:
            ReturnLogo = LollandFalsterBioenergi;
            break;
    }

    return <ReturnLogo />;
};
