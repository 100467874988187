import React from "react";
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import S from "./AuthorBlock.module.scss";

interface AuthorBlockProps {
    authorImageUrl?: string;
    authorImageName?: string;
    authorName?: string;
    authorTitle?: string;
    authorDate?: string;
}

export const AuthorBlock = ({ ...props }: AuthorBlockProps) => {
    return (
        <div className={S.author}>
            {props?.authorImageUrl && (
                <div className={S.authorImage}>
                    <div className={S.authorImageItem}>
                        <ImageViewer
                            src={props?.authorImageUrl}
                            aspectRatioX={1}
                            aspectRatioY={1}
                            alternative={props?.authorImageName}
                        />
                    </div>
                </div>
            )}

            <div className={S.authorInfo}>
                {props?.authorName && <span className={S.authorInfoName}>{props?.authorName}</span>}
                {props?.authorTitle && (
                    <span className={S.authorInfoTitle}>{props?.authorTitle}</span>
                )}
            </div>
            {props.authorDate && <div className={S.authorDate}>{props.authorDate}</div>}
        </div>
    );
};
